.customhead {
    background-color: transparent;
}

.customhead:hover {
    background-color: rgba(238, 238, 238, 200);
}

.sortbutton {
    background-color: transparent;
}

.sortbutton:hover {
    background-color: rgba(238, 238, 238, 200);
}

.headercontainer {
    display: flex;
    align-self: center;
    justify-content: center;
}